import React, { useState, useEffect, useCallback } from 'react';
import { getConfigParams } from './server';
import axios from "axios";
const { location } = window;
const url = location.href;
const getQueryStringArgs =() =>{
  //取得查询字符串并去掉开头的问号
  let qs = (location.search.length > 0 ? location.search.substring(1) : ""),
  //保存数据的对象
  args = {},
  //取得每一项
  items = qs.length ? qs.split("&") : [],
  item = null,
  name = null,
  value = null,
  //在for 循环中使用
  i = 0,
  len = items.length;
  //逐个将每一项添加到args 对象中
  for (i=0; i < len; i++){
    item = items[i].split("=");
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args;
}
const timestamp = String(parseInt(Date.now() / 1000)); // 生成签名用的时间戳
const nonce_str = 'Wm3WZYTPz0wzccnW'; // 生成签名用的随机字符串
const jsb = ''; // 自定义测试用JSB方法名
const params = {}; // 自定义测试用JSB方法参数

export default () => {
  const [sdk, setSdk] = useState(null);
  const [ready, setReady] = useState(false);
  const [configStatus, setConfigStatus] = useState('');
  const [resultMsg, setResultMsg] = useState('');
  const [client_key, setClientKey] = useState('');

  // 打开原生授权页面JSB能力示例
  const onClickAuth = () => {
    if (ready) {
      sdk.showOpenAuth({
        params: {
          client_key,
          state: '',
          scopes: {
            user_info: 0, // 0: 必选；1: 可选，默认不选中； 2: 可选，默认选中
            star_top_score_display: 0,
            star_author_score_display: 0,
            star_tops: 0,
            // user_unique_info: 0,
            'alliance.kol.store_manage': 0,
            'alliance.kol.buyin_id': 0,
            'alliance.kol.reputation': 0,
            'alliance.kol.orders': 0,
            'alliance.kol.materials': 0,
            'data.external.user':0,
            'data.external.fans_source':0,
            'data.external.item':0
          },          
          response_type: 'code'
        },
        // success: ( res ) => setResultMsg(`Auth Success: ${JSON.stringify(res)}`),
        success:  ( res ) => {
          const { ticket } = res;
          const args = getQueryStringArgs();
          const user_id = args.user_id
         axios.get(
            `https://liuzhu.org.cn/dy-api/auth/getWebAccessToken?code=${ticket}&id=${user_id}`,
          ).then(async res => {
            if(res.code == 200) {
               setResultMsg(`授权成功，请关闭此页面`)  
            }else {
               setResultMsg(res.msg || res.data?.msg || `该抖音号已被其他设备绑定，请解绑后重试`)
            }
          }).catch(err => {
              
          });
        },
        error: res => setResultMsg(`Auth Error: ${JSON.stringify(res)}`)
      });
    }
  };

  const testClick = async () => {
    const access_token = 'act.3._MMWfzz6Mo1ee3bQhCXd2q16FnAe1KlJgNKN8RJrR_iD3iwvTaGEkXem6rX48-RcHbbrSlwhIECVFjk1WRLTK-hfFhzmHoZM1nIdJwTR5RPYgtHl9RMmipop45bDFpoaBFgT0pOCs_FCmmiI33osxk_U8dGqLHsG6LYAtisVC9PXpMTN-2MlGwkSm4s=_lq'
    const open_id = '_0009lUQSOQmwAFacgTcDh34efcR7VN_osHT'
    const obj = {
      open_id,
      nickName:'',
      headimgUrl:'',
      tiktok:'',
      fansNum:''
    }
    //调用获取抖音的用户信息接口
   const {data:data1} =  await axios.post(
      `https://open.douyin.com/data/external/user/fans/`,
      
        new URLSearchParams({  
          date_type:7, 
          open_id
        })
      ,
      {
        headers: {
          'access-token':access_token,
          'Content-Type': 'application/json',
        },
      },
    )
    console.log("data1",data1);
  }

  // 可在URL参数中自定义JSB方法名和参数以测试
  const onClickJSB = () => {
    if (ready) {
      sdk[jsb]({
        params,
        success: res => setResultMsg(`JSB ${jsb} Success: ${JSON.stringify(res)}`),
        error: res => setResultMsg(`JSB ${jsb} Error: ${JSON.stringify(res)}`)
      });
    }
  };


  // 使用JSB能力前，必须先通过Config验证签名
  const config = useCallback(async () => {
    const { client_key, signature } = await getConfigParams({ timestamp, nonce_str, url:'https://liuzhu.org.cn' });
    sdk.config({
      params: {
        client_key,
        signature,
        timestamp,
        nonce_str,
        url:'https://liuzhu.org.cn'
      }
    });
    setClientKey(client_key);
  }, [sdk]);

  useEffect(() => {
    setSdk(window.douyin_open);
    if (sdk) {
      setConfigStatus('请稍等。。。');
      config();
      sdk.ready(() => {
        setReady(true);
        // setConfigStatus('SDK Config Ready');
      });
      sdk.error(res => {
        setConfigStatus(`SDK Config Error: ${JSON.stringify(res)}`);
      });
    }
  }, [sdk, config]);

  return (
    <>
      {/* <p onClick={() => location.reload()} className="link">{'Reload'}</p> */}
      {/* <p>{configStatus} </p> */}
      {<div className='imgs'>
        <img src={require('./assets/logo.png')} alt="" /><img src={require('./assets/bangding.png')} alt="" /><img src={require('./assets/douyin.png')} alt="" />
      </div>}
      {
        !ready ? <p>加载中请稍等...</p> : (
          <>
            <p onClick={onClickAuth} className="auth-btn">点击授权</p>
            {/* {jsb && <p onClick={onClickJSB} className="link" style={{ textTransform: 'capitalize' }}>{jsb}</p>} */}
          </>
        )
      }
      <p>{resultMsg}</p>
      {/* <p onClick={testClick}>test</p> */}
    </>
  );
};